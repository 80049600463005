import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet'
import CarouselImpl from '../components/CarouselImpl';

// Icons
import { FaWind, FaTv, FaWifi, FaBath, FaUtensils, FaUserAlt, FaCarSide, FaCamera, FaBroom, FaLuggageCart } from 'react-icons/fa';

export default () => (
  <Layout>
    <section className="text-center">
      <Helmet title="Kost Eksklusif Mara Juara Depok" defer={false} />
      <div className="text-center items-center content-center">
        <div className="text-center">
          <div className="items-center md:my-10 md:mx-20">
            <CarouselImpl />
          </div>
          <h1 className="text-center text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            <span className="hidden md:inline">
              Kost Eksklusif
              Mara Juara Depok
            </span>
            <span className="md:hidden">
              Kost Eksklusif <br/> 
              Mara Juara Depok
            </span>
          </h1>
          <p className="text-center text-xl lg:text-2xl mt-6 font-light">
            Tempat tinggal terbaik untuk menjadi juara!
          </p>
          <p className="flex flex-col text-center items-center">
            <a href="https://api.whatsapp.com/send?phone=628111688890&text=Halo%20saya%20tertarik%20dengan%20kost%20Mara%20Juara!">
              {/* <Button className="hidden md:inline-block md:text-left" size="lg">Dapatkan Promo!</Button> */}
              <Button className="text-base" size="md">Dapatkan Promo!</Button>
              </a>
          </p>
          <p className="mt-4 mb-0 text-gray-600 text-base text-center lg:text-lg ">
            <b>Promo Better Normal</b> <br/>
            Diskon Sewa Bulanan hingga IDR 300k!</p>
          <p className="mt-0 mb-4 text-gray-600 text-base text-center lg:text-lg ">Penawaran terbatas hingga akhir bulan ini!</p>
        </div>
        {/* <div className="items-center w-11/12 lg:w-1/2 rounded lg:my-20 "> */}
          {/* <HeroImage /> */}
          {/* <img src="http://marajuara.local/wp-content/uploads/2020/08/3744-8-scaled.jpg"></img> */}
          
        {/* </div> */}
      </div>
    </section>
    <section id="features" className="pt-5 mx-5 lg:mx-0 lg:pb-20 lg:pt-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold mb-1">Fasilitas</h2>
        <p className="text-gray-600 text-center">Fasilitas terbaik untuk penghuni terbaik.</p>
        {/* First Row */}
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-8 lg:mt-12">
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
              <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaWind style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
                <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Air Conditioner (AC)</p>
                <p className="lg:mt-5 lg:mb-0 text-sm lg:text-lg text-center">
                  AC dalam semua kamar.
                </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaTv style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Cable Television</p>
              <p className="lg:mt-5 lg:mb-0 text-sm lg:text-lg text-center">
                Nikmati tayangan jernih dalam kamar.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaWifi style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Free Wifi</p>
              <p className="lg:mt-5 lg:mb-0 text-sm lg:text-lg text-center">
                Internet 24 jam nonstop.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaBath style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Hot Water Shower</p>
              <p className="lg:mt-5 lg:mb-0 text-sm lg:text-lg text-center">
                Kebersihan dan kenyamanan di setiap kamar.
              </p>
            </Card>
          </div>
        </div>
        {/* Second Row */}
        <div className="flex flex-col sm:flex-row sm:-mx-3">
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaLuggageCart style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Laundry</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Pakaian selalu bersih tanpa kotor sedikitpun.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaBroom style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Housekeeping</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Kamar dibersihkan setiap hari.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaUtensils style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Kantin</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Tidak perlu khawatir saat mencari makanan.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaBath style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Living Room</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Belajar dan berdiskusi bersama penghuni lain.
              </p>
            </Card>
          </div>
        </div>
        {/* Third Row */}
        <div className="flex flex-col sm:flex-row sm:-mx-3">
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaUtensils style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Dapur</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Memasak makanan dan minuman sesukamu.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaUserAlt style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Security</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Pengamanan terbaik.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaCamera style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">CCTV</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Pemantauan 24 jam.
              </p>
            </Card>
          </div>
          <div id="last-card-before-rooms" className="flex-1 px-1 lg:px-3 lg:my-4">
            <Card className="mb-4 lg:mb-8">
            <div className="font-semibold text-sm lg:text-xl lg:mb-2"><FaCarSide style={{display: "inline", textAlign: "center", fontSize: "2em"}} /></div>
              <p className="lg:mt-5 lg:mb-0 font-semibold text-sm lg:text-xl text-center">Free Parking</p>
              <p className="lg:mt-5 text-sm lg:text-lg text-center">
                Parkir mobil dan motor gratis.
              </p>
            </Card>
          </div>
        </div>
        {/* Fourth Row */}
        {/* <div className="flex flex-col sm:flex-row sm:-mx-3">
          
        </div> */}
      </div>
    </section>
    <section id="rooms" className="">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold mt-8 lg:mt-10 mb-1">Kamar</h2>
        <p className="text-gray-600 text-center">Pilihan ruang yang sesuai dengan kebutuhanmu!</p>
        <SplitSection
          id="services"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Regular Bedroom</h3>
              <p className="mt-8 text-xl font-light leading-relaxed text-justify">
              Kamar terbaik untuk kamu yang sedang bekerja atau berkuliah di Depok dan sekitarnya. Kamar full furnished dengan fasilitas lengkap: kasur, AC, TV, free WIFI, kamar mandi dalam, air panas, lemari baju, meja belajar dan rak buku.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <s>Rp 2.000.000/bulan </s>
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <span className="font-bold">Rp 1.799.000/bulan + FREE PARKIR 1 TAHUN</span>
              </p>
            </div>
          }
          secondarySlot={<img className="rounded" src="https://marajuara.web.id/wp-content/uploads/2020/10/Reguler-Bedroom.jpg"></img>}
        />
      
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                VIP Bedroom
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed text-justify">
               Kamar eksklusif yang nyaman untuk kamu yang sedang bekerja atau berkuliah di Depok dan sekitarnya. Lengkap dengan ruang kongkow produktif. Kamar full furnished dengan fasilitas lengkap: kasur, AC, TV, free WIFI, kamar mandi dalam, air panas, lemari baju, meja belajar dan rak buku.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <s>Rp 2.200.000/bulan </s>
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <span className="font-bold">Rp 1.999.000/bulan + FREE PARKIR 1 TAHUN</span>
              </p>
            </div>
          }
          secondarySlot={<img className="rounded" src="https://marajuara.web.id/wp-content/uploads/2020/10/VIP-Bedroom.jpg" />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Large Bedroom
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed text-justify">
                Kamar terbaik untuk berdua, cocok untuk pasangan suami istri atau teman dekat. Kamar luas full furnished dengan fasilitas lengkap: kasur, AC, TV, free WIFI, kamar mandi dalam, air panas, lemari baju, meja belajar dan rak buku. Pilihan Kasur king size atau twin bed.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <s>Rp 3.000.000/bulan </s>
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <span className="font-bold">Rp 2.699.000/bulan + FREE PARKIR 1 TAHUN</span>
              </p>
            </div>
          }
          secondarySlot={<img className="rounded" src="https://marajuara.web.id/wp-content/uploads/2020/10/Large-Bedroom.jpg" />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                All-Girls Bedroom
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed text-justify">
                Kamar terbaik khusus untuk wanita dan perempuan. Kamar full furnished dengan fasilitas lengkap: kasur, AC, TV, free WIFI, kamar mandi dalam, air panas, lemari baju, meja belajar dan rak buku.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <s>Rp 2.000.000/bulan </s>
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed">
                <span className="font-bold">Rp 1.799.000/bulan + FREE PARKIR 1 TAHUN</span>
              </p>
            </div>
          }
          secondarySlot={<img className="rounded" src="https://marajuara.web.id/wp-content/uploads/2020/10/All-Girls-Bedroom.jpg"/>}
        />
      </div>
    </section>
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto ">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Artikel</h2>
        <LabelText className="mb-8 text-gray-600 text-center">Dapatkan informasi terkini seputar kehidupan kost</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3"> 
          <div className="flex-1 px-3">
            <StaticQuery query ={
              graphql
              `
              {
                allWordpressWpPortfolio(sort: {fields: date, order: DESC}, limit: 3) {
                  edges {
                    node {
                      title
                      date
                      excerpt
                    }
                  }
                }
              }
              `
            }render={props => (
              <div>
                {props.allWordpressWpPortfolio.edges.map(item=>(
                  <div key={item.node.title} className="flex-1 px-3">
                    <CustomerCard customer={item.node} />
                  </div>
                ))}
              </div>
            )} />
          </div>
        </div>
      </div>
    </section> */}
    <section className="container w-11/12 mx-5 lg:mx-auto my-10 py-20 bg-gray-200 rounded-lg text-center">
      <h3 className="text-4xl lg:text-5xl font-semibold">Testimoni Penghuni</h3>
      <p className="mt-2 mb-8 mx-4 text-lg font-light text-center">
        Cari tau keseruan para penghuni Kost Eksklusif Mara Juara Depok!
      </p>
      <span className="px-2 mt-8 text-center">
        <a href="https://www.google.com/search?q=mara+juara+depok&oq=mara+juara+&gs_lcp=CgZwc3ktYWIQARgAMgIIADICCAA6CwgAELADEAcQChAeOgkIABCwAxAHEB46BAgAEBM6BAguEBM6CAgAEAcQHhATOgQIABAeUKMIWIYXYNwhaAFwAHgAgAGEAYgB-wKSAQMzLjGYAQCgAQGqAQdnd3Mtd2l6yAEKwAEB&sclient=psy-ab#lrd=0x2e69ebd0bfe700b3:0x97602dfa151643e3,1,,,">
          <Button className="px-2">Cek Disini!</Button>
        </a>
      </span>
      {/* <span className="px-2 mt-8 text-center">
        <Button class="button-blue text-secondary">Lihat Lebih Jauh &gt;&gt; </Button>
      </span> */}
    </section>
  </Layout>
);
