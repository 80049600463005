{/* <div class="carousel relative shadow-2xl bg-white">
	<div class="carousel-inner relative overflow-hidden w-full">
	    <!--Slide 1-->
		<input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
		<div class="carousel-item absolute opacity-0" style="height:50vh;">
			<div class="block h-full w-full bg-indigo-500 text-white text-5xl text-center">Slide 1</div>
		</div>
		<label for="carousel-3" class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
		<label for="carousel-2" class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
		
		<!--Slide 2-->
		<input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
		<div class="carousel-item absolute opacity-0" style="height:50vh;">
			<div class="block h-full w-full bg-orange-500 text-white text-5xl text-center">Slide 2</div>
		</div>
		<label for="carousel-1" class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
		<label for="carousel-3" class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label> 
		
		<!--Slide 3-->
		<input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="">
		<div class="carousel-item absolute opacity-0" style="height:50vh;">
			<div class="block h-full w-full bg-green-500 text-white text-5xl text-center">Slide 3</div>
		</div>
		<label for="carousel-2" class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
		<label for="carousel-1" class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>

		<!-- Add additional indicators for each slide-->
		<ol class="carousel-indicators">
			<li class="inline-block mr-3">
				<label for="carousel-1" class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700">•</label>
			</li>
			<li class="inline-block mr-3">
				<label for="carousel-2" class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700">•</label>
			</li>
			<li class="inline-block mr-3">
				<label for="carousel-3" class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700">•</label>
			</li>
		</ol>
		
	</div>
</div> */}

import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class CarouselImpl extends Component {
	render(){
		return (
			<Carousel>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Kamar-Kost-Mara-Juara-Depok-e1592728254690.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Kamar-Kost-2-Mara-Juara-Depok-e1592728312867.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Kamar-dan-Toilet-Kost-Mara-Juara-Depok.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Lorong-dan-Kantin-Kost-Eksklusif-Mara-Juara-Depok.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Dapur-dan-Laundry-Kost-Eksklusif-Mara-Juara-Depok.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Kantin-dan-Parkir-Kost-Eksklusif-Mara-Juara-Depok.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Balkon-Kost-Eksklusif-Mara-Juara-Depok.jpg" />
				</div>
				<div>
					<img className="carousel-img" src="https://marajuara.web.id/wp-content/uploads/2020/06/Tampak-Luar-Kost-Eksklusif-Mara-Juara-Depok.jpg" />
				</div>
			</Carousel>
		);
	}
}

export default CarouselImpl;